@font-face {
  font-family: "Circular Std";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("assets/fonts/CircularStd-Book.ttf") format("truetype");
}
@font-face {
  font-family: "Circular Std";
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("assets/fonts/CircularStd-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Circular Std";
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url("assets/fonts/CircularStd-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Circular Std";
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url("assets/fonts/CircularStd-Black.ttf") format("truetype");
}
@font-face {
  font-family: "Circular Std";
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("assets/fonts/CircularStd-BookItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Circular Std";
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src: url("assets/fonts/CircularStd-MediumItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Circular Std";
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src: url("assets/fonts/CircularStd-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Circular Std";
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src: url("assets/fonts/CircularStd-BlackItalic.ttf") format("truetype");
}
::-webkit-scrollbar {
  width: 0.4em;
}
::-webkit-scrollbar:horizontal {
  height: 0.4em;
}
::-webkit-scrollbar-corner {
  background-color: transparent;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 100px;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
body {
  /* color: #fff; */
}
.tween {
  opacity: 0;
}
@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
